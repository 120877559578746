// @flow
import React from 'react'

import Modal from 'components/Modal'
import VideoPlayer from 'components/VideoPlayer'

import styles from './styles.module.scss'

export default function VideoPlayButton (props: { videoHref: string,}) {

    const videoPlayer = React.useRef()
    const [ isModalOpened, setIsModalOpened ] = React.useState(false)

    const handlePlayVideo = React.useCallback(() => {
        setIsModalOpened(true)
    }, [])

    const playVideo = React.useCallback(() => {
        videoPlayer.current.play()
    }, [videoPlayer])

    return (<>
        <svg onClick={handlePlayVideo} className={styles.playButton} width="117px" height="115px" viewBox="0 0 117 115" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(6.000000, 6.000000)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <ellipse strokeOpacity="0.5" stroke={'#ec1b2e'} strokeWidth="6" fill={'#ec1b2e'} cx="52.5" cy="51.5" rx="55.5" ry="54.5"/>
                <path
                    d="M59.7524318,35.1862397 L78.3698894,69.0361625 C78.9022021,70.0040039 78.5491364,71.2201191 77.5812951,71.7524318 C77.2860051,71.9148413 76.9544635,72 76.6174576,72 L39.3825424,72 C38.2779729,72 37.3825424,71.1045695 37.3825424,70 C37.3825424,69.6629941 37.4677011,69.3314525 37.6301106,69.0361625 L56.2475682,35.1862397 C56.7798809,34.2183983 57.9959961,33.8653326 58.9638375,34.3976454 C59.2961569,34.580421 59.5696561,34.8539202 59.7524318,35.1862397 Z"
                    fill={'#fffcf3'}
                    transform="translate(58.000000, 52.000000) rotate(-270.000000) translate(-58.000000, -52.000000) "
                />
            </g>
        </svg>

        <Modal afterOpen={playVideo} isOpen={isModalOpened} onCancel={() => setIsModalOpened(false)}>
            <VideoPlayer url={props.videoHref} ref={videoPlayer}/>
        </Modal>
    </>)
}
